import React from "react";

// libraries
import { Button, Dropdown, Nav, Navbar } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

// data
import bookData from "../../data/booksData.json";

import { useAuthDetails, useLogout } from "../../hooks";

const Navigation = () => {
  const { authDetails } = useAuthDetails();
  const { logout } = useLogout();
  let slug = useLocation();

  const BuildLink = ({ name, loc, command }) => {
    return (
      <Nav.Item>
        <Nav.Link as={Link} to={loc} className="pt-0">
          {command === "logout" ? (
            <Button
              className="navLink"
              variant="outline-dark"
              onClick={() => logout()}
            >
              {name}
            </Button>
          ) : (
            <Button
              className="navLink"
              variant="outline-dark"
              active={slug.pathname === loc ? true : false}
            >
              {name}
            </Button>
          )}
        </Nav.Link>
      </Nav.Item>
    );
  };

  const BooksLink = () => {
    const seriesArr = [];

    for (let i = 0; i < bookData.length; i++) {
      if (seriesArr.length <= 0) seriesArr.push(bookData[i].series);
      else {
        if (!seriesArr.find((name) => name === bookData[i].series))
          seriesArr.push(bookData[i].series);
      }
    }

    return (
      <Dropdown>
        <Dropdown.Toggle
          variant="outline-dark"
          className="navLink"
          active={slug.pathname === "/books" ? true : false}
        >
          Books
        </Dropdown.Toggle>
        <Dropdown.Menu variant="dark">
          <Dropdown.Item as={Link} to="/books">
            Bloodlines of Atmos, Jace Book 1
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/books">
            Bloodlines of Atmos, Jace Book 2
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/books">
            Bloodlines of Atmos, Jace Book 3
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  return (
    <>
      <Navbar
        expand="md"
        sticky="top"
        className="navBarBG mb-2 pb-0"
        variant="dark"
      >
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="d-flex justify-content-between w-100">
            <div className="d-xs-block d-md-flex me-auto">
              <BuildLink name="Home" loc="/" />
              <BooksLink />
              <BuildLink name="Store" loc="/store" />
              <BuildLink name="Events" loc="/events"/>
              <BuildLink name="Blog" loc="/blog" />
              <BuildLink name="About" loc="/about" />

              <BuildLink name="Contact" loc="/contact" />



     

              
            </div>
            <div>
              <BuildLink name="Let's Play" loc="/lets-play" />
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default Navigation;
