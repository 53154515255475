import React, { useState, useEffect } from "react";

import { Button, ButtonGroup, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import Game from "./Game";
import Pagination from "../elements/Pagination";
import IconTwitch from "../elements/Icon_Twitch";
import IconYouTube from "../elements/Icon_YouTube";

import { createIntFromGameID } from "../../utilities";

import letsPlayData from "../../data/letsPlayData.json";
import gameLibraryData from "../../data/gameLibraryData.json";

const Schedule = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [gamesPerPage, setGamesPerPage] = useState(5);

  const [letsPlayDataList, setLetsPlayDataList] = useState(letsPlayData);
  const [sortOrder, setSortOrder] = useState("old-new");

  const sortList = () => {
    if (letsPlayDataList[0].id === "lets-play-001") {
      setLetsPlayDataList((curr) =>
        [...curr].sort(
          (a, b) => createIntFromGameID(b.id) - createIntFromGameID(a.id)
        )
      );
      setSortOrder("new-old");
    } else {
      setLetsPlayDataList((curr) =>
        [...curr].sort(
          (a, b) => createIntFromGameID(a.id) - createIntFromGameID(b.id)
        )
      );
      setSortOrder("old-new");
    }
  };

  useEffect(() => {
    setLetsPlayDataList((curr) => [...curr].sort((a, b) => b.id - a.id));
  }, []);

  useEffect(() => {
    // console.log("full library", letsPlayDataList)
    // console.log("edited library", [...letsPlayDataList]
    //   .splice(firstPostIndex, lastPostIndex))
  },[currentPage])

  const lastPostIndex = currentPage * gamesPerPage;
  const firstPostIndex = lastPostIndex - gamesPerPage;

  // add search function later

  return (
    <>
      <div className="generalHeader headerBG px-4">Let's Play!</div> <div className="generalText"> J. P. Edgar started to stream himself while playing games and interact with fans. </div> <div className="generalText"> See the schedule for his streams below, or watch previous streams on the replay channel. </div> <div className="generalHeader mt-4">Schedule</div> <div className="generalText">Tuesdays and Thursdays 2p PST.</div> <Button href="http://www.youtube.com/@jpedgar-plays-live" target="_blank" rel="noreferrer" style={{ width: "15rem" }} className="d-flex  align-items-center my-1" > <span style={{ width: "40px" }}> <IconYouTube /> </span> <span className="ps-1">Go to YouTube channel</span> </Button> <Button href="http://www.youtube.com/@jpedgar-plays-live" target="_blank" rel="noreferrer" style={{ width: "15rem" }} className="d-flex  align-items-center" > <span style={{ width: "40px" }}> <IconTwitch /> </span> <span className="ps-1">Go to Twitch channel</span> </Button> <div className="generalHeader mt-4">Games</div> <ButtonGroup className="mb-1"> <Button href="http://www.youtube.com/@jpedgar-plays" target="_blank" rel="noreferrer" style={{ width: "15rem" }} className="d-flex  align-items-center" > <span style={{ width: "40px" }}> <IconYouTube /> </span> <span className="ps-1">Go to replay library</span> </Button> {sortOrder === "new-old" && ( <Button variant="success" className="mx-1 d-flex align-items-center justify-content-center" style={{ width: "15rem" }} onClick={() => sortList()} > Sorted (newest to oldest) </Button> )} {sortOrder === "old-new" && ( <Button variant="success" className="ms-1 d-flex align-items-center  justify-content-center" style={{ width: "15rem" }} onClick={() => sortList()} > Sorted (oldest to newest) </Button> )} {/* <Button as={Link} to="/game-library" variant="info"> Potential Upcoming Let's-Play Games </Button> */} </ButtonGroup>

      <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} gamesPerPage={gamesPerPage} dataLength={letsPlayDataList.length}/>

      {[...letsPlayDataList]
        .splice(firstPostIndex, lastPostIndex)
        .map((letsPlayDataListInfo) => {
          const gameLibraryDataInfo = gameLibraryData.find(
            (gameInfo) =>
              gameInfo.letsPlay.letsPlayID === letsPlayDataListInfo.id
          );
          const letsPlayData = { ...letsPlayDataListInfo };
          const libraryData = { ...gameLibraryDataInfo };
          const gameData = { letsPlayData, libraryData };
          return (
            gameData && (
              <Game key={gameData.letsPlayData.id} gameData={gameData} />
            )
          );
        })}

      <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} gamesPerPage={gamesPerPage} dataLength={letsPlayDataList.length}/>
    </>
  );
};

export default Schedule;
