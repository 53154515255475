import React, { useEffect, useState } from "react";

import { Card, Row, Col, Button, Image, ButtonGroup } from "react-bootstrap";

const Game = ({ gameData }) => {
  const {
    episodeCount,
    externalLink,
    extraDetail,
    playlist,
    streamEnd,
    streamStart,
    thumbnail,
    title,
  } = gameData.letsPlayData;

  const { console: gameConsole } = gameData.libraryData;

  return (
    <div>
      <Row
        style={{
          backgroundColor: "rgb(50, 50, 50)",
          padding: "0.5rem",
          borderRadius: "5px",
          margin: "0.5rem 0 0.5rem 0",
        }}
      >
        <Col xs={12} md={6} lg={4}>
          <a href={playlist} target="_blank" rel="noreferrer">
            <Image
              src={thumbnail}
              thumbnail
              style={{ width: "20rem", cursor: "pointer" }}
            />
          </a>
        </Col>
        <Col className="d-flex flex-column justify-content-between">
          <div>
            <div className="generalHeader">
              <i>{title}</i>
              <span className="d-none d-sm-inline ms-2">
                on the {gameConsole}
              </span>
            </div>
            <div className="d-none d-sm-block generalText">{extraDetail}</div>
            <div className="generalText">
              Streamed from {streamStart} to {streamEnd}
            </div>
            <div className="generalText">Episode Count: {episodeCount}</div>
          </div>
          <ButtonGroup>
            {playlist && (
              <Button href={playlist} target="_blank" className="w-50">
                Go To YouTube Playlist
              </Button>
            )}

            <Button
              href={externalLink}
              target="_blank"
              variant="info"
              className="w-50"
            >
              Go To Wiki Page
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    </div>
  );
};

export default Game;
