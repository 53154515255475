import React from "react";

import Events from "./Events";

const index = () => {
  return (
    <>
      <Events />
    </>
  );
};

export default index;
