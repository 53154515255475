import React from "react";

import { Row, Container } from "react-bootstrap";

import Event from "./Event";

import { textToMili } from "../../utilities";

import eventData from "../../data/eventsData.json";

const Events = () => {
  const currDate = new Date();

  return (
    <>
      <Container>
        <Row>
          {eventData.map((data) => {
            return data.visibilityFlag &&
              textToMili(data.date) > textToMili(currDate) ? (
              <Event key={data.id} eventData={data} />
            ) : null;
          })}
        </Row>
      </Container>
    </>
  );
};

export default Events;
