import React, { useState, useEffect } from "react";

import { Pagination as BSPagination } from "react-bootstrap";

const Pagination = () => {
// const Pagination = ({currentPage = 1, setCurrentPage, gamesPerPage = 5, dataLength = 0}) => {


    const [currentPage, setCurrentPage] = useState(1)
    const gamesPerPage = 5 
    const dataLength = 20



  const [indexBlock, setIndexBlock] = useState(1);

  const maxPageNumber = Math.ceil(dataLength / gamesPerPage);
  const maxIndexBlock = Math.ceil(maxPageNumber / 5);

  const handleShowFirst = () => setCurrentPage(1);
  const handleShowLast = () => setCurrentPage(maxPageNumber);
  const handleShowPrevious = () => { if (currentPage > 1) setCurrentPage((curr) => curr - 1); };
  const handleShowNext = () => { if (currentPage < maxPageNumber) setCurrentPage((curr) => curr + 1); };
  const handlePageJump = (jump) => {setCurrentPage(jump)};
  const handleEllipsis = (direction) => {
    if (direction === "back") { if (indexBlock > 1) { setCurrentPage((indexBlock - 1) * gamesPerPage - 4); } } 
    else if (direction === "forward") { if (indexBlock < maxIndexBlock) { setCurrentPage((indexBlock + 1) * gamesPerPage - 4); } }
  };

  const getIndexBlock = () => { return Math.ceil(currentPage / gamesPerPage); };

  useEffect(() => {
    console.log("currentPage = ", currentPage)
    const block = getIndexBlock();
    if (block !== indexBlock) setIndexBlock(block);
  }, [currentPage]);


  const [test, setTest] = useState([])


  useEffect(() => {
    console.log("indexBlock = ", indexBlock)




        const testArr = []
        for (let i = 0; i < 10; i++) {
          testArr.push(i)
        }

        setTest(testArr.splice(0, 5))


  }, [indexBlock])



useEffect(() => {

    console.log(test)
}, [test])

  return (
    <div>
      <BSPagination>
        <BSPagination.First onClick={() => handleShowFirst()} />
        <BSPagination.Prev onClick={() => handleShowPrevious()} />
        {indexBlock > 1 && ( <BSPagination.Item onClick={() => handlePageJump(1)}> {1} </BSPagination.Item> )}
        {indexBlock > 1 && ( <BSPagination.Ellipsis onClick={() => handleEllipsis("back")} /> )}

        {indexBlock * gamesPerPage - gamesPerPage - 4 <= maxPageNumber && ( <BSPagination.Item onClick={() => handlePageJump(indexBlock * gamesPerPage - 4)} 
            active={currentPage === indexBlock * gamesPerPage - 4} > 
            {indexBlock * gamesPerPage - gamesPerPage + 1} </BSPagination.Item> )}
        {indexBlock * gamesPerPage - 3 <= maxPageNumber && ( <BSPagination.Item onClick={() => handlePageJump(indexBlock * gamesPerPage - 3)} 
            active={currentPage === indexBlock * gamesPerPage - 3} > 
            {indexBlock * gamesPerPage - gamesPerPage + 2} </BSPagination.Item> )}
        {indexBlock * gamesPerPage - 2 <= maxPageNumber && ( <BSPagination.Item onClick={() => handlePageJump(indexBlock * gamesPerPage - 2)} 
            active={currentPage === indexBlock * gamesPerPage - 2} > 
            {indexBlock * gamesPerPage - gamesPerPage + 3} </BSPagination.Item> )}
        {indexBlock * gamesPerPage - 1 <= maxPageNumber && ( <BSPagination.Item onClick={() => handlePageJump(indexBlock * gamesPerPage - 1)} 
            active={currentPage === indexBlock * gamesPerPage - 1} > 
            {indexBlock * gamesPerPage - gamesPerPage + 4} </BSPagination.Item> )}
        {indexBlock * gamesPerPage <= maxPageNumber && ( <BSPagination.Item onClick={() => handlePageJump(indexBlock * gamesPerPage)} 
            active={currentPage === indexBlock * gamesPerPage} > 
            {indexBlock * gamesPerPage  + gamesPerPage + 5} </BSPagination.Item> )}

        {indexBlock < maxIndexBlock && ( <BSPagination.Ellipsis onClick={() => handleEllipsis("forward")} /> )}
        {indexBlock < maxIndexBlock && ( <BSPagination.Item onClick={() => handlePageJump(maxPageNumber)}> {maxPageNumber} </BSPagination.Item> )}
        <BSPagination.Next onClick={() => handleShowNext()} />
        <BSPagination.Last onClick={() => handleShowLast()} />
      </BSPagination>
    </div>
  );
};

export default Pagination;
