import React from "react";

import { Card, Row, Col } from "react-bootstrap";

const Event = ({ eventData }) => {
  const { date, time, type } = eventData;
  const { street, city, state, miscInfo } = eventData.location;

  return (
    <>
      <Card style={{ width: "18rem" }}>
        <Card.Header>
          <b>{type}</b>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col xs={5}>Location:</Col>
            <Col>
              {street}
              <br />
              {city}, {state}
              {miscInfo && (
                <>
                  <br />
                  {miscInfo}
                </>
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={5}>Date:</Col>
            <Col>{date}</Col>
          </Row>
          <Row>
            <Col xs={5}>Time:</Col>
            <Col>{time}</Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default Event;
