const textToMili = (data) => {
  const dateDate = new Date(data);
  return dateDate.getTime();
};

const miliToText = (data) => {
  // long day, year e.g. February 27, 2025
  const dateData = new Date(data);
  return dateData.toLocaleString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
};

export { textToMili, miliToText };
